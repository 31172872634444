import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Layout } from "../../Layout";

import * as S from "../../styles/pages/Expedicion.styles";

const Expedicion = () => {
  return (
    <Layout title="Expedición">
      <S.Container>
        <p>
          Una cámara fotográfica de fuelle atestiguo entre 1919 y 1924 el ocaso
          de una era, a lo largo del estrecho de Magallanes, sur de Chile.
        </p>

        <p>
          El sacerdote Martin Gusinde formuló su investigación como una suerte
          de etnografía de rescate donde presuroso debía tratar de registar lo
          que aún quedaba antes de ser sustituido por el veloz avance de la
          modernidad.
        </p>

        <p>
          Uno de los registros mas relevantes de la cultura Selk`nam fueron
          estos documentos plasmados en placas de vidrio por medio de la técnica
          gelatino de bromuro.
        </p>

        <hr />

        <StaticImage
          src="../../images/expedicion/0.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/expedicion/1.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/expedicion/2.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <hr />

        <StaticImage
          src="../../images/expedicion/3.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <hr />

        <StaticImage
          src="../../images/expedicion/4.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <p>
          Este Proyecto nace con el objetivo de conmemorar los 100 años de la
          ruta trazada por Martín Gusinde desde su primera expedición y volver a
          recorrer los mismos periplos descritos en sus libros de campo, con la
          misma cámara que testifico el choque entre dos mundos.
        </p>
      </S.Container>
    </Layout>
  );
};

export default Expedicion;
